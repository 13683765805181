import { useEffect } from 'react';
import ReactDOMServer from 'react-dom/server';

import { useChecklistsContext } from '@/contexts/ChecklistsContext';
import { ChecklistsBeacon } from '@/components/checklists/ChecklistsBeacon';

const HIGHLIGHT_SHADOW_CLASS =
  'shadow-[0px_0px_1px_5px_rgba(192,_121,_180,_1),0px_0px_2px_8px_rgba(231,_200,_226,_1)]';

const HIGHLIGHTED_ELEMENT_CLASSES = [
  HIGHLIGHT_SHADOW_CLASS,
  '[animation-duration:2s]',
  'animate-pulse',
  'relative'
];

export function useChecklistHighlights() {
  const { highlightedFeatures, setHighlightedFeatures } = useChecklistsContext();

  useEffect(() => {
    const onFeatureClick = (e: MouseEvent) => {
      const clickedElement = e.target as HTMLElement | null;

      const featureElement = clickedElement?.dataset.feature
        ? clickedElement
        : clickedElement?.closest<HTMLElement>('[data-feature]');

      if (!featureElement) return;

      setHighlightedFeatures(
        highlightedFeatures.filter((f) => f !== featureElement.dataset.feature)
      );
    };

    const restoreFeature = (featureElement: HTMLElement) => {
      const { feature } = featureElement.dataset;
      if (feature && !highlightedFeatures.includes(feature)) {
        featureElement.classList.remove(...HIGHLIGHTED_ELEMENT_CLASSES);
        featureElement.removeEventListener('click', onFeatureClick);
      }
    };

    const highlight = (observer: MutationObserver) => {
      observer.disconnect(); // Disconnect the observer before making changes

      // Get all current beacons
      const currentBeacons = document.querySelectorAll('[data-type="beacon"]');

      // Remove beacons that are not in the highlightedFeatures array
      currentBeacons.forEach((beacon) => {
        const featureElement = beacon.closest<HTMLElement>('[data-feature]');

        beacon.remove();

        if (featureElement) {
          restoreFeature(featureElement);
        }
      });

      // Add new beacons for each feature in the highlightedFeatures array
      highlightedFeatures.forEach((feature) => {
        const elements = document.querySelectorAll<HTMLElement>(`[data-feature="${feature}"]`);
        elements.forEach((div) => {
          if (!div.querySelector('[data-type="beacon"]')) {
            const xOffset = parseInt(div.dataset.featureXOffset ?? '0', 10);
            const yOffset = parseInt(div.dataset.featureYOffset ?? '0', 10);

            const beacon = document.createElement('div');
            beacon.dataset.type = 'beacon';
            beacon.style.top = `${yOffset}px`;
            beacon.style.right = `${xOffset}px`;
            beacon.className =
              'absolute flex items-center justify-center translate-x-1/2 -translate-y-1/2 w-12 h-12 overflow-hidden';
            beacon.innerHTML = ReactDOMServer.renderToStaticMarkup(<ChecklistsBeacon />);
            div.appendChild(beacon);
            div.classList.add(...HIGHLIGHTED_ELEMENT_CLASSES);
            div.addEventListener('click', onFeatureClick);
          }
        });
      });

      observer.observe(document.body, {
        childList: true,
        subtree: true
      }); // Reconnect the observer after making changes
    };

    // Observe for changes in the DOM
    const observer = new MutationObserver(() => highlight(observer));
    observer.observe(document.body, {
      childList: true,
      subtree: true
    });

    // Initial addition
    highlight(observer);

    return () => {
      observer.disconnect();
    };
  }, [highlightedFeatures]);
}
