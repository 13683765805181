export type Checklist = {
  version: number;
  sections: ChecklistSection[];
};

export type ChecklistSection = {
  id: string;
  steps: ChecklistStep[];
};

export type ChecklistStep = {
  definition: ChecklistDefinition;
  completed_at: string | null;
};

export type ChecklistDefinition = {
  version: number;
  id: string;
  blocked_by: string;
  type: ChecklistType;
  description: boolean;
  article_url?: string;
  video_url?: string;
  extra: {
    source?: string;
    url?: string;
    link?: string;
  };
};

export enum ChecklistType {
  Video = 'video',
  Documentation = 'documentation',
  InProduct = 'in-product',
  None = 'none'
}
