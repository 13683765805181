import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiInformationCircle as InfoIcon } from 'react-icons/hi2';
import { Switch, Tooltip } from '@knack/asterisk-react';

import { type AddonsType } from '@/enums/addons';
import { useAddonPlanMutation } from '@/hooks/api/mutations/useAddonMutation';
import { ConfirmDisableAutoIncreaseDialog } from './ConfirmDisableAutoIncreaseDialog';

type AutoIncreaseProps = {
  addonType: AddonsType;
  checked: boolean;
  disabled: boolean;
};

export function AutoIncrease({ addonType, checked, disabled }: AutoIncreaseProps) {
  const [t] = useTranslation();
  const [isSwitchChecked, setIsSwitchChecked] = useState(checked);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const { changeAutoIncreaseMutation } = useAddonPlanMutation();
  const isLoading = changeAutoIncreaseMutation.isPending;

  const handleAutoIncreaseMutation = () => {
    changeAutoIncreaseMutation.mutate({
      autoIncrease: !isSwitchChecked,
      planType: addonType
    });
    setIsDialogOpen(false);
    setIsSwitchChecked(!isSwitchChecked);
  };

  const onSwitchChange = () => {
    if (isSwitchChecked) {
      setIsDialogOpen(true);
    } else {
      handleAutoIncreaseMutation();
    }
  };

  const handleDialogClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <div className="mb-6 justify-between">
      <Switch
        disabled={disabled}
        data-testid="auto-increase-transactions-switch"
        onCheckedChange={onSwitchChange}
        checked={isSwitchChecked}
      />
      <span className={`ml-1 align-top ${checked ? 'text-emphasis' : 'text-muted'}`}>
        {t('components.billing.overview.flows.switch_title')}
      </span>
      {disabled && (
        <Tooltip>
          <Tooltip.Trigger className="ml-1 inline-block max-w-[30ch] truncate align-middle text-subtle">
            {' '}
            <InfoIcon className="text-subtle" size={18} />
          </Tooltip.Trigger>
          <Tooltip.Content>
            {t('components.billing.overview.flows.disabled_tooltip')}
          </Tooltip.Content>
        </Tooltip>
      )}
      <span className="mt-2 block text-sm text-subtle">
        {t('components.billing.overview.flows.switch_description')}
      </span>

      {isDialogOpen && (
        <ConfirmDisableAutoIncreaseDialog
          open={isDialogOpen}
          isLoading={isLoading}
          onClose={handleDialogClose}
          onConfirm={handleAutoIncreaseMutation}
        />
      )}
    </div>
  );
}
