import React, { useRef } from 'react';
import { Skeleton, Tooltip } from '@knack/asterisk-react';

import { useHasOverflow } from '@/hooks/useHasOverflow';
import { type SampleAppIllustration } from '@/pages/apps/create-app/illustrations/types';

type SampleAppButtonProps = {
  title: string;
  illustration: SampleAppIllustration;
  isLoading?: boolean;
  onClick?: () => void;
};

export function SampleAppButton({
  title,
  illustration,
  isLoading = false,
  onClick
}: SampleAppButtonProps) {
  const textRef = useRef<HTMLDivElement>(null);
  const hasTextOverflow = useHasOverflow(textRef, 'horizontal');

  if (isLoading) {
    return (
      <div className="flex flex-1 flex-col gap-2 lg:w-0">
        <Skeleton className="h-24 sm:h-32 lg:h-24" />
        <Skeleton className="h-4" />
      </div>
    );
  }

  return (
    <Tooltip open={hasTextOverflow ? undefined : false}>
      <Tooltip.Trigger asChild>
        <button
          onClick={onClick}
          type="button"
          className="group flex flex-1 cursor-pointer flex-col gap-2 text-left lg:w-0"
        >
          <div
            className={`relative h-24 w-full overflow-hidden rounded-md sm:h-32 lg:h-24 ${illustration.className}`}
          >
            <div className="absolute inset-0 z-10 bg-black bg-opacity-0 transition-colors group-hover:bg-opacity-20" />
            <div className="flex size-full items-center justify-center drop-shadow-2xl [&>*]:max-h-full">
              {illustration.component}
            </div>
          </div>
          <div
            ref={textRef}
            className="w-full overflow-hidden text-ellipsis text-nowrap text-xs font-medium underline decoration-transparent underline-offset-[3px] transition-colors group-hover:decoration-current"
          >
            {title}
          </div>
        </button>
      </Tooltip.Trigger>
      <Tooltip.Content>{title}</Tooltip.Content>
    </Tooltip>
  );
}
