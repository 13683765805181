import { HiChevronLeft } from 'react-icons/hi2';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { ChecklistsProgress } from '@/components/checklists/ChecklistsProgress';
import { ChecklistsStep } from '@/components/checklists/ChecklistsStep';
import { type ChecklistStep } from '@/components/checklists/types';

type Props = {
  id: string;
  onGoBack?: () => void;
  canGoBack?: boolean;
};

export function ChecklistsExpandedSection({ id, onGoBack, canGoBack = true }: Props) {
  const { getSectionById, isStepLocked } = useChecklists();
  const section = getSectionById(id);

  return (
    <div className="flex flex-col gap-8">
      {canGoBack && (
        <div className="flex flex-col gap-4">
          <button
            type="button"
            className="flex items-center gap-1 text-sm text-subtle"
            onClick={onGoBack}
          >
            <HiChevronLeft />
            Back
          </button>

          <ChecklistsProgress
            section={id}
            steps={{
              total: section.steps.length,
              completed: section.completed
            }}
          />
        </div>
      )}
      <div className="flex h-full max-h-[500px] flex-col gap-2 overflow-y-auto overflow-x-hidden pr-2">
        {section.steps.map((step: ChecklistStep) => (
          <ChecklistsStep key={step.definition.id} step={step} isLocked={isStepLocked(step)} />
        ))}
      </div>
    </div>
  );
}
