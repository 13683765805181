import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import { Badge, Button, Dialog } from '@knack/asterisk-react';

import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { AiInput } from './AiInput';

export function AiModal({
  open,
  disabled,
  onClose
}: {
  open?: boolean;
  disabled?: boolean;
  onClose?: (event?: string) => void;
}) {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(open);
  const [submitEnabled, setSubmitEnabled] = useState(false);
  const Footer = (
    <Dialog.Footer>
      <Button
        intent="minimal"
        onClick={() => {
          setIsOpen(false);
          if (onClose) onClose('cancel');
        }}
        disabled={disabled}
        data-testid="create-app-ai-create-modal-close-button"
      >
        {t('actions.cancel')}
      </Button>
      <Button
        type="submit"
        form="ai-query-form"
        data-testid="create-app-ai-query-form-submit"
        disabled={!submitEnabled}
      >
        {t('actions.create')}
      </Button>
    </Dialog.Footer>
  );
  const onOpenChange = (openState: boolean) => {
    if (!openState && onClose) {
      onClose('cross');
    }
    setIsOpen(open);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onOpenChange}>
      <DashboardDialogContent footer={Footer}>
        <div
          className="-mt-6 mb-2 flex content-center justify-start"
          data-testid="create-app-ai-create-modal"
        >
          <svg width="0" height="0">
            <linearGradient id="bg-gradient" x1="00%" y1="100%" x2="0%" y2="0%">
              <stop style={{ stopColor: 'rgb(var(--brand-600))' }} offset="0%" />
              <stop style={{ stopColor: 'rgb(var(--pink-400))' }} offset="100%" />
            </linearGradient>
          </svg>
          <Dialog.Header className="mb-6">
            <div className="flex items-center">
              <Dialog.Title>{t('components.create_app.ai.customize_my_app')}</Dialog.Title>
              <AiStarsIcon
                className="ml-2 mr-2 mt-1 flex-none rotate-90"
                size={24}
                style={{ fill: 'url(#bg-gradient)' }}
              />
              <span>
                <Badge className="text-xs"> {t('product.beta')}</Badge>
              </span>
            </div>
            <Dialog.Description className="text-xs">
              {t('components.create_app.ai.ai_description_example')}
            </Dialog.Description>
          </Dialog.Header>
        </div>
        <div className="w-full">
          <AiInput setSubmitEnabled={setSubmitEnabled} />
        </div>
      </DashboardDialogContent>
    </Dialog>
  );
}
