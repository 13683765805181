import { HiMinus as MinusIcon, HiPlus as PlusIcon } from 'react-icons/hi2';
import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';
import { type TransactionsItem } from './TransactionsAddon';

interface CounterProps {
  item: TransactionsItem;
  onChange: (item: TransactionsItem, quantity: number) => void;
}

export function Counter({ item, onChange }: CounterProps) {
  return (
    <div className="flex items-center space-x-2">
      <Button
        intent="minimal"
        onClick={() => onChange(item, item.quantity - 1)}
        disabled={item.quantity <= 0}
      >
        <MinusIcon
          className={cn(item.quantity === 0 ? 'text-subtle' : 'fill-[url(#svg-gradient-1)]')}
          size={16}
        />
      </Button>
      <span>{item.quantity}</span>
      <Button intent="minimal" onClick={() => onChange(item, item.quantity + 1)}>
        <PlusIcon className="fill-[url(#svg-gradient-1)]" size={16} />
      </Button>
    </div>
  );
}
