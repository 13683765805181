import {
  Component,
  createElement,
  type ComponentType,
  type ErrorInfo,
  type ReactNode
} from 'react';

interface Props {
  fallbackUI: ComponentType<{ resetError: () => void }>;
  onError?: (error: Error, errorInfo: ErrorInfo) => void;
  children?: ReactNode;
}

interface State {
  hasError: boolean;
}

export class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  public static getDerivedStateFromError(): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    const { onError } = this.props;
    if (onError) {
      onError(error, errorInfo);
    }
  }

  public render() {
    const { hasError } = this.state;
    const { fallbackUI, children } = this.props;

    if (hasError) {
      return createElement(fallbackUI, {
        resetError: () => this.setState({ hasError: false })
      });
    }

    return children;
  }
}
