import { type SampleApp } from '@/types/apps';
import { BASE_IMAGE_URL, TEMPLATES_IMG_FORMAT } from '@/pages/apps/create-app/constants';

const renameFilterBeforeCheckTemplate = (key: string) => {
  let useCaseKey = key;
  switch (key) {
    case 'directories':
      useCaseKey = 'directory';
      break;
    case 'equations':
      useCaseKey = 'calculator';
      break;
    case 'portals':
      useCaseKey = 'portal';
      break;
    case 'payments':
    case 'e-commerce':
      useCaseKey = 'payment';
      break;
    case 'education':
      useCaseKey = 'schools';
      break;
    default:
      break;
  }
  return useCaseKey;
};

export const getUrlForAICard = (selectedUseCase: string, isDarkMode: boolean) => {
  let useCaseKey = selectedUseCase;
  if (selectedUseCase === 'directories') {
    useCaseKey = 'directory';
  }
  if (selectedUseCase === 'equations') {
    useCaseKey = 'calculator';
  }
  if (selectedUseCase === 'portals') {
    useCaseKey = 'portal';
  }
  if (selectedUseCase === 'payments' || selectedUseCase === 'e-commerce') {
    useCaseKey = 'payment';
  }

  if (selectedUseCase === 'other') {
    useCaseKey = 'portal';
  }

  return `${BASE_IMAGE_URL}${isDarkMode ? 'dark' : 'light'}-${useCaseKey}.svg`;
};

/**
 * Get Apps filtered by selected industry and use case
 * and sort by the `score` property if found, in descending order 5 --> 0
 * @returns {SampleApp[]} - Array of SampleApp objects to show in the UI, the 3 best scored
 */
export const getTemplateAppsFilteredAndSorted = (
  selectedIndustry: string,
  selectedUseCase: string,
  sampleApps: SampleApp[]
) => {
  if (!selectedIndustry || !selectedUseCase) {
    return [];
  }

  if (selectedIndustry === 'other') {
    return sampleApps.filter(
      (templateToFilter: SampleApp) =>
        templateToFilter.slug === 'contact-directory' ||
        templateToFilter.slug === 'quotes-and-invoices'
    );
  }
  return sampleApps
    ?.filter(
      (templateToFilter: SampleApp) =>
        templateToFilter.categories &&
        templateToFilter.categories?.includes(renameFilterBeforeCheckTemplate(selectedIndustry))
    )
    .sort((a: SampleApp, b: SampleApp) => (b.score || 0) - (a.score || 0))
    .slice(0, 3);
};

// Given the link that is the full URL of the template, return name and format of the file
// In the public-cdn repo, as the slug located there is the urlSlug, not the appSlug
export const getCardImage = (link: string) => {
  const image = TEMPLATES_IMG_FORMAT.find((img) => link.includes(img.urlSlug));
  if (!image) {
    return null;
  }
  return image;
};
