export const queryKeys = {
  apps: {
    applications: 'applications',
    sharedApplications: 'shared-applications',
    sampleApplications: 'sample-applications',
    builders: 'builders'
  },
  auth: {
    forgotPasswordConfirm: 'forgot-password-confirm',
    session: 'session'
  },
  billing: {
    overview: 'billing-overview',
    stripeSecret: 'stripe-secret',
    invoices: 'invoices',
    apiPlan: 'api-plan',
    records: 'records',
    storage: 'storage',
    transactions: 'transactions',
    coupon: 'coupon',
    addons: 'addons',
    isDowngradeAllowed: 'is-downgrade-allowed'
  },
  tax_ids: 'taxIds',
  plans: 'plans',
  planDowngradeInfo: 'plan-downgrade-info',
  account: 'account',
  qrCodeGen: 'gr-code-gen',
  gravatar: 'gravatar',
  hubspotToken: 'hubspot-token',
  csrfToken: 'csrf-token',
  videoResources: 'video-resources',
  flows: {
    history: 'flows-history',
    transactionsUsage: 'transactions-usage',
    account_status: 'account-status'
  }
} as const;
