import { Button } from '@knack/asterisk-react';

import { cn } from '@/utils/tailwind';

export function NavItem({
  isActive,
  title,
  onClick,
  ...props
}: {
  isActive: boolean;
  title: string;
  onClick: () => void;
}) {
  return (
    <Button
      onClick={onClick}
      intent="minimalStandalone"
      className={cn(
        'mb-1 block w-full px-2 py-2.5 text-left hover:bg-subtle',
        isActive && 'bg-emphasis text-emphasis'
      )}
      data-active={isActive}
      {...props}
    >
      {title}
    </Button>
  );
}
