import { createContext, useContext, useMemo, useState, type ReactNode } from 'react';

type PostUpdatePlanProviderContextState = {
  shouldShowAutoIncreaseBanner: boolean;
  setShouldShowAutoIncreaseBanner: (shouldShowAutoIncreaseBanner: boolean) => void;
  hasBannerBeenShown: boolean;
  setHasBannerBeenShown: (hasBannerBeenShown: boolean) => void;
};

const contextDefaultValues: PostUpdatePlanProviderContextState = {
  shouldShowAutoIncreaseBanner: false,
  setShouldShowAutoIncreaseBanner: () => {},
  hasBannerBeenShown: false,
  setHasBannerBeenShown: () => {}
};

const PostUpdatePlanContext =
  createContext<PostUpdatePlanProviderContextState>(contextDefaultValues);

export function PostUpdatePlanProvider({ children }: { children: ReactNode }) {
  const [shouldShowAutoIncreaseBanner, setShouldShowAutoIncreaseBanner] = useState(
    contextDefaultValues.shouldShowAutoIncreaseBanner
  );
  const [hasBannerBeenShown, setHasBannerBeenShown] = useState(
    contextDefaultValues.hasBannerBeenShown
  );

  const PostUpdatePlanValues = useMemo<PostUpdatePlanProviderContextState>(
    () => ({
      shouldShowAutoIncreaseBanner,
      setShouldShowAutoIncreaseBanner,
      hasBannerBeenShown,
      setHasBannerBeenShown
    }),
    [shouldShowAutoIncreaseBanner, setShouldShowAutoIncreaseBanner]
  );

  return (
    <PostUpdatePlanContext.Provider value={PostUpdatePlanValues}>
      {children}
    </PostUpdatePlanContext.Provider>
  );
}

export const usePostUpdatePlanContext = () => useContext(PostUpdatePlanContext);
