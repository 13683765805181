import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useAddonPlanQuery } from '@/hooks/api/queries/useAddonPlanQuery';
import { isFlagEnabled } from '@/utils/flagsmith';
import { ApiAddon } from './ApiAddon';
import { TransactionsAddon } from './TransactionsAddon';

export type AddonItemAction = 'upgrade' | 'downgrade' | 'remove';

export type AddonItem = {
  calls?: number;
  transactions?: number;
  records?: number;
  storage?: number;
  price: number;
  quantity: number;
};
export function AddOns() {
  const { data: addons } = useAddonPlanQuery();
  const { data: account } = useAccountQuery();
  const isFlowsEnabled = process.env.NODE_ENV === 'development' || isFlagEnabled('albato_flows');
  if (!addons || !account) {
    return null;
  }

  return (
    <div className="grid gap-6 xl:grid-cols-2">
      <ApiAddon currentApiPlan={addons?.api[0] ?? null} />
      {isFlowsEnabled && (
        <TransactionsAddon
          currentTransactionsPlan={addons?.transactions ?? null}
          account={account}
        />
      )}
    </div>
  );
}
