import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { useIsSharedBuilderOnly } from '@/hooks/useSession';
import { ensureFlagsmithIdentity, isFlagEnabled } from '@/utils/flagsmith';

export const useSetupWizardPermissions = () => {
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { data: session } = useSessionQuery();
  const { data: account } = useAccountQuery();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isRedirectRequired, setIsRedirectRequired] = useState(false);

  const isForbidden = !account?.isTrial || isSharedBuilderOnly || account?.applications.length > 0;

  useEffect(() => {
    const isComingFromEmbeddedSignUp = searchParams.get('embed') === 'true';
    const hasWelcomePath = !!searchParams.get('welcomePath');

    if (!isComingFromEmbeddedSignUp || hasWelcomePath) return;

    if (session?.account.id) {
      void ensureFlagsmithIdentity(session).then(() => {
        if (!isForbidden) {
          setIsRedirectRequired(isFlagEnabled('setup_wizard_v1'));
        }

        searchParams.delete('embed');
        setSearchParams(searchParams);
      });
    }
  }, [session, isForbidden]);

  return { isRedirectRequired, isForbidden };
};
