import { useQuery } from '@tanstack/react-query';
import axios from 'axios';

import { queryKeys } from '@/hooks/api/queryKeys';

interface IsDowngradeAllowedResponse {
  enabled: boolean;
}

async function getIsDowngradeAllowed(accountId: string): Promise<IsDowngradeAllowedResponse> {
  const endpoint = `/v1/accounts/${accountId}/billing/allow-downgrade`;

  const { data } = await axios.get<IsDowngradeAllowedResponse>(endpoint, {
    withCredentials: true
  });

  return data;
}

export function useIsDowngradeAllowedQuery(accountId: string) {
  return useQuery({
    queryKey: [queryKeys.billing.isDowngradeAllowed],
    queryFn: () => getIsDowngradeAllowed(accountId),
    staleTime: 1000 * 30
  });
}
