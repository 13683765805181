import { Trans, useTranslation } from 'react-i18next';
import { Button, Select } from '@knack/asterisk-react';

import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { SETUP_WIZARD_STEPS } from '@/pages/apps/create-app/constants';

interface StepSelectIndustryProps {
  setCurrentStep: (step: string) => void;
  selectedIndustry: string | undefined;
  setSelectedIndustry: (value: string | undefined) => void;
  selectedUseCase: string | undefined;
  setSelectedUseCase: (value: string | undefined) => void;
  defaultPathForTranslation: string;
  getTitleFromFilter: (key: string) => string;
  industries: string[];
  useCases: string[];
  isLoading: boolean;
}

export function StepSelectIndustry({
  setCurrentStep,
  selectedIndustry,
  setSelectedIndustry,
  selectedUseCase,
  setSelectedUseCase,
  defaultPathForTranslation,
  getTitleFromFilter,
  industries,
  useCases,
  isLoading
}: StepSelectIndustryProps) {
  const [t] = useTranslation();
  const session = useSession();

  return (
    <div className="grid size-full grid-cols-1 items-center gap-6 sm:grid-cols-2">
      <div className="col-span-1 flex justify-center">
        <img
          src="//cdn.knack.com/assets/setup-wizard/images/v1.0.0/step1-illustration.svg"
          alt={t(`${defaultPathForTranslation}.step_1.title`)}
        />
      </div>
      <div className="col-span-1 flex flex-col justify-center space-y-4">
        <p className="flex items-center gap-2">
          <Trans i18nKey={`${defaultPathForTranslation}.step_1.industry`}>
            <Select onValueChange={(value) => setSelectedIndustry(value)} value={selectedIndustry}>
              <Select.Trigger
                size="lg"
                placeholder={t(`${defaultPathForTranslation}.step_1.select_industry`)}
              />
              <Select.Content className="min-w-[200px]">
                {industries.map((industry) => (
                  <Select.Item key={industry} value={industry}>
                    {getTitleFromFilter(industry)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </Trans>
        </p>
        <p className="text-body-2 flex items-center gap-2 text-default">
          <Trans i18nKey={`${defaultPathForTranslation}.step_1.use_case`}>
            <Select
              onValueChange={(value) => setSelectedUseCase(value)}
              value={selectedUseCase}
              disabled={!selectedIndustry}
            >
              <Select.Trigger
                size="lg"
                placeholder={t(`${defaultPathForTranslation}.step_1.select_use_case`)}
              />
              <Select.Content className="min-w-[200px]">
                {useCases.map((useCase) => (
                  <Select.Item key={useCase} value={useCase}>
                    {getTitleFromFilter(useCase)}
                  </Select.Item>
                ))}
              </Select.Content>
            </Select>
          </Trans>
        </p>
        <div className="mt-6">
          <Button
            disabled={!selectedIndustry || !selectedUseCase || isLoading}
            type="button"
            intent="primary"
            onClick={() => {
              if (shouldSendAnalytics(session.user.email)) {
                void window.analytics.track('Setup Wizard: Industry and Use Case sent', {
                  groupId: session.account.id,
                  industry: selectedIndustry,
                  useCase: selectedUseCase
                });
              }
              setCurrentStep(SETUP_WIZARD_STEPS[1]);
            }}
          >
            {t('actions.next')}
          </Button>
        </div>
      </div>
    </div>
  );
}
