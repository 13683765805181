import { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { useSession } from '@/hooks/useSession';
import { getWelcomePath } from '@/utils/querystrings';
import { WelcomePath } from '@/pages/apps/create-app/constants';

export type WelcomePathAutomation = {
  path: WelcomePath.Template;
  extra: {
    templateId: string;
  };
};

// This handles the welcome path automation that is set from embedded forms. All its variants are
// described in this document: https://knack.atlassian.net/wiki/x/BICAl
export const useWelcomePathAutomation = () => {
  const session = useSession();
  const [searchParams, setSearchParams] = useSearchParams();
  const [automation, setAutomation] = useState<WelcomePathAutomation>();

  useEffect(() => {
    if (!session.user.id) {
      return;
    }

    if (getWelcomePath() === WelcomePath.Template) {
      const templateId = searchParams.get('templateId');

      if (!templateId) {
        return;
      }

      setAutomation({
        path: WelcomePath.Template,
        extra: {
          templateId
        }
      });

      searchParams.delete('templateId');
    }

    // Cleanup needed to prevent it from running multiple times
    searchParams.delete('welcomePath');
    setSearchParams(searchParams);
  }, [searchParams, session]);

  return automation;
};
