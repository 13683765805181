import { useState, type Dispatch, type SetStateAction } from 'react';
import { useTranslation } from 'react-i18next';
import { HiExclamationCircle as ExclamationIcon } from 'react-icons/hi2';
import { Banner, Button, Dialog, useToast } from '@knack/asterisk-react';

import { AddonsType } from '@/enums/addons';
import { useAddonPlanMutation } from '@/hooks/api/mutations/useAddonMutation';
import { useApiPlanMutation } from '@/hooks/api/mutations/useApiPlanMutation';
import { getOrdinalNumber } from '@/utils/formatters';
import { DashboardDialogContent } from '@/components/ui/DashboardDialogContent';
import { type AddonItem, type AddonItemAction } from './AddOns';

interface ConfirmAddonPlanDialogProps {
  open: boolean;
  setIsOpen: Dispatch<SetStateAction<boolean>>;
  selectedItem: AddonItem;
  selectedAction: AddonItemAction;
  hasAddonPlan: boolean;
}

export function ConfirmAddonPlanDialog({
  open,
  setIsOpen,
  selectedItem,
  selectedAction,
  hasAddonPlan
}: ConfirmAddonPlanDialogProps) {
  const { presentToast } = useToast();
  const [t] = useTranslation();
  const [errorMessage, setErrorMessage] = useState('');

  const { setApiPlanLimitMutation, removeApiPlanMutation } = useApiPlanMutation();

  const { setAddonMutation, removeAddonMutation } = useAddonPlanMutation();

  const isLoading = setApiPlanLimitMutation.isPending || removeApiPlanMutation.isPending;

  const getAddonType = () =>
    (selectedItem.calls && AddonsType.Api) ||
    (selectedItem.transactions && AddonsType.Transactions) ||
    (selectedItem.storage && AddonsType.Storage) ||
    (selectedItem.records && AddonsType.Records) ||
    AddonsType.Default;

  const handleUpdateAddonPlanLimit = () => {
    setAddonMutation.mutate(
      { quantity: selectedItem.quantity, planType: getAddonType() },
      {
        onSuccess: () => {
          presentToast({
            title: t('components.billing.addons.plan_update_success')
          });
          setIsOpen(false);
        },
        onError: () => {
          setErrorMessage(t('errors.generic_error'));
        }
      }
    );
  };

  const handleRemoveAddon = () => {
    removeAddonMutation.mutate(getAddonType(), {
      onSuccess: () => {
        presentToast({
          title: t('components.billing.addons.plan_remove_success')
        });
        setIsOpen(false);
      },
      onError: () => {
        setErrorMessage(t('errors.generic_error'));
      }
    });
  };

  const onModalClose = () => {
    setErrorMessage('');
  };

  const dialogTitle = () => {
    if (selectedAction === 'remove') {
      return t('components.billing.addons.api_calls_remove_calls');
    }

    if (hasAddonPlan) {
      return t('components.billing.addons.api_calls_select_calls_pack');
    }

    return t('components.billing.addons.api_calls_more_calls');
  };

  const dialogContent = () => {
    const date = new Date();
    const currentDayOfMonth = date.getDate();

    if (selectedAction === 'remove') {
      return t(
        selectedItem.calls
          ? 'components.billing.addons.api_calls_remove_description'
          : 'components.billing.addons.transactions.remove_description'
      );
    }

    if (hasAddonPlan) {
      if (selectedItem.calls) {
        return t('components.billing.addons.api_calls_change_selection_description', {
          numberOfCalls: selectedItem.calls.toLocaleString(),
          price: selectedItem.price.toLocaleString(),
          billingDay: getOrdinalNumber(currentDayOfMonth)
        });
      }

      if (selectedItem.transactions) {
        return t('components.billing.addons.transactions.change_selection_description', {
          numberOfTransactions: selectedItem.transactions.toLocaleString(),
          price: selectedItem.price.toLocaleString(),
          billingDay: getOrdinalNumber(currentDayOfMonth)
        });
      }
    }

    if (selectedItem.calls) {
      return t('components.billing.addons.api_calls_upgrade_new_plan_description', {
        numberOfCalls: selectedItem.calls.toLocaleString(),
        price: selectedItem.price.toLocaleString(),
        billingDay: getOrdinalNumber(currentDayOfMonth)
      });
    }

    if (selectedItem.transactions) {
      return t('components.billing.addons.transactions.upgrade_new_plan_description', {
        numberOfTransactions: selectedItem.transactions.toLocaleString(),
        price: selectedItem.price.toLocaleString(),
        billingDay: getOrdinalNumber(currentDayOfMonth)
      });
    }
    return null;
  };

  const dialogFooter = () => (
    <Dialog.Footer intent="borderless">
      <Dialog.Close asChild>
        <Button intent="minimal" onClick={() => setIsOpen(false)}>
          {t('actions.cancel')}
        </Button>
      </Dialog.Close>
      {selectedAction === 'remove' ? (
        <Button
          type="submit"
          intent="destructive"
          isLoading={isLoading}
          onClick={handleRemoveAddon}
        >
          {t('actions.remove')}
        </Button>
      ) : (
        <Button type="submit" isLoading={isLoading} onClick={handleUpdateAddonPlanLimit}>
          {t('actions.purchase')}
        </Button>
      )}
    </Dialog.Footer>
  );

  return (
    <Dialog open={open} onOpenChange={setIsOpen}>
      <DashboardDialogContent
        headerTitle={dialogTitle()}
        footer={dialogFooter()}
        onCloseAutoFocus={onModalClose}
      >
        {dialogContent()}

        {errorMessage && (
          <Banner className="mt-6" intent="destructive" icon={ExclamationIcon}>
            <Banner.Message>{errorMessage}</Banner.Message>
          </Banner>
        )}
      </DashboardDialogContent>
    </Dialog>
  );
}
