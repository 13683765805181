import { useChecklists } from '@/hooks/checklists/useChecklists';
import { ChecklistsProgress } from './ChecklistsProgress';

export function ChecklistsTitle() {
  const { totalCompleted, totalCount } = useChecklists();

  return (
    <div className="flex items-center gap-3">
      <div className="flex-1">
        <ChecklistsProgress steps={{ total: totalCount, completed: totalCompleted }} />
      </div>
    </div>
  );
}
