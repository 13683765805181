import { useTranslation } from 'react-i18next';

interface TransactionNotInitializedProps {
  transactionsNumber: number;
}

export function TransactionNotInitialized({ transactionsNumber }: TransactionNotInitializedProps) {
  const [t] = useTranslation();
  return (
    <div className="grid gap-6 lg:grid-cols-1">
      <h2>{t('components.billing.overview.flows.init_more_info.title')}</h2>
      <p>
        {t('components.billing.overview.flows.init_more_info.content-1', { transactionsNumber })}
      </p>
      <p className="text-ellipsis">
        {t('components.billing.overview.flows.init_more_info.content-2')}
      </p>
    </div>
  );
}
