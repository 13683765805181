import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, colorPalette, ProgressRing, Tooltip } from '@knack/asterisk-react';

import { useChecklists } from '@/hooks/checklists/useChecklists';
import { cn } from '@/utils/tailwind';
import { ChecklistsPopoverContent } from '@/components/checklists/ChecklistsPopoverContent';
import Portal from '@/components/ui/Portal';

export function ChecklistsNavButton({ className = '' }: { className?: string }) {
  const [t] = useTranslation();
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLButtonElement>(null);
  const { totalCount, totalCompleted } = useChecklists();

  const progress = Math.round((totalCompleted / totalCount) * 100);

  return (
    <>
      <Tooltip>
        <Tooltip.Trigger asChild ref={ref} onClick={() => setIsOpen(!isOpen)}>
          <Button
            intent="secondary"
            className={cn(
              'inline-flex justify-evenly gap-0 border-success px-2 transition-colors hover:border-success hover:bg-subtle focus:outline-green-400 dark:border-green-600 dark:hover:border-green-400',
              {
                'outline outline-2 outline-offset-2 outline-green-400': isOpen
              },
              className
            )}
          >
            <ProgressRing
              width={26}
              backgroundColor="stroke-green-100"
              foregroundColor={colorPalette.green['400'] as string}
              strokeWidth={3}
              percentage={progress}
            />
            <div className="ml-1 whitespace-nowrap font-medium text-success-default">
              {t('components.checklists.nav_button.title')}
            </div>
          </Button>
        </Tooltip.Trigger>
        <Tooltip.Content>
          {t('components.checklists.nav_button.completion', {
            progress
          })}
        </Tooltip.Content>
      </Tooltip>
      {isOpen && (
        <Portal>
          <ChecklistsPopoverContent triggerRef={ref} onClose={() => setIsOpen(false)} />
        </Portal>
      )}
    </>
  );
}
