import { useEffect } from 'react';
import { Helmet } from 'react-helmet-async';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import { useToast } from '@knack/asterisk-react';
import { useQueryClient } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import axios from 'axios';

import { Route } from '@/enums';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSessionQuery } from '@/hooks/api/queries/useSessionQuery';
import { setAxiosInterceptors, setDefaultAxiosUrlBase } from '@/utils/axiosConfig';
import { oldToNewDashboardUrlMapping } from '@/utils/redirects';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';

import '@/index.css';

import { queryKeys } from '@/hooks/api/queryKeys';
import { useChecklistHighlights } from '@/hooks/checklists/useChecklistHighlights';
import { useChecklists } from '@/hooks/checklists/useChecklists';
import { useChecklistsStepInitiator } from '@/hooks/checklists/useChecklistsStepInitiator';
import { useIsIframeSession } from '@/hooks/useIsIframeSession';
import { SignUpIFrameFallback } from '@/pages/sign-up';

setDefaultAxiosUrlBase(import.meta.env.PUBLIC_API_URL);
setAxiosInterceptors();

export function App({ children }: { children?: React.ReactNode }) {
  useChecklistHighlights();
  const navigate = useNavigate();
  const { hash } = useLocation();
  const { presentToast } = useToast();
  const queryClient = useQueryClient();
  const [t] = useTranslation();

  // The account query is a 'lazy query', and it's dependent on the session. If the session is empty, we don't fetch the account.
  // `isLoading` is a derived flag that is computed from `isFetching && isPending` so it will only be true if the query is currently fetching for the first time.
  const { isPlaceholderData, isFetching: isSessionFetching } = useSessionQuery();
  const { isLoading: isAccountLoading } = useAccountQuery();
  const isIframeSession = useIsIframeSession();
  useChecklistsStepInitiator();
  const { totalCompleted, totalCount } = useChecklists();

  useEffect(() => {
    if (hash) {
      let parsedHash = hash;
      let queryString = '';

      if (hash.includes('?')) {
        [parsedHash, queryString] = hash.split('?');
        queryString = `?${queryString}`;
      }
      // Remove # and #/ so we can handle both
      parsedHash = parsedHash.replace('#/', '').replace('#', '');

      if (parsedHash.includes('knack-password/reset/')) {
        navigate({
          pathname: `${Route.ResetPassword}/${parsedHash.replace('knack-password/reset/', '')}`,
          search: queryString
        });
      } else if (parsedHash.includes('template/')) {
        navigate({
          pathname: `${Route.Apps}/${Route.Install}/${parsedHash.replace('template/', '')}`,
          search: queryString
        });
      } else if (oldToNewDashboardUrlMapping[parsedHash]) {
        navigate({
          pathname: oldToNewDashboardUrlMapping[parsedHash],
          search: queryString
        });
      }
    }
  }, [hash, navigate]);

  useEffect(() => {
    const id = axios.interceptors.response.use((response) => {
      if (response.headers['knack-checklist-step']) {
        response.headers['knack-checklist-step'].split(',').forEach((step: string) => {
          let title = t('components.checklists.step_completed_next', {
            task: t(`components.checklists.steps.${step}`)
          });

          if (step === 'create_account') {
            title = t('components.checklists.first_step_completed');
          } else if (totalCompleted === totalCount - 1) {
            title = t('components.checklists.step_completed', {
              task: t(`components.checklists.steps.${step}`)
            });
          }

          presentToast({
            className: 'cursor-pointer',
            intent: 'success',
            title
          });
        });

        void queryClient.invalidateQueries({
          queryKey: [queryKeys.auth.session]
        });
      }

      return response;
    });

    return () => {
      axios.interceptors.response.eject(id);
    };
  }, [presentToast, queryClient, t]);

  if (isSessionFetching || isAccountLoading || isPlaceholderData) {
    return <FullPageSpinner />;
  }

  const isIframe = window.self !== window.top;

  return (
    <>
      {isIframe && (
        <Helmet>
          <style>{'body {background-color: transparent;}'}</style>
        </Helmet>
      )}
      <ReactQueryDevtools buttonPosition="bottom-right" initialIsOpen={false} />
      {isIframeSession ? <SignUpIFrameFallback /> : children}
    </>
  );
}
