import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Banner, Progress } from '@knack/asterisk-react';

import { useSampleAppsQuery } from '@/hooks/api/queries/useSampleAppsQuery';
import { WizardLayout } from '@/components/layout/WizardLayout';
import {
  SETUP_WIZARD_INDUSTRIES_USE_CASES,
  SETUP_WIZARD_STEPS
} from '@/pages/apps/create-app/constants';
import { StepSelectCard } from './StepSelectCard';
import { StepSelectIndustry } from './StepSelectIndustry';

export function SetupWizardSteps() {
  const [t] = useTranslation();
  const { data: sampleApps, isLoading } = useSampleAppsQuery();
  const [currentStep, setCurrentStep] = useState(SETUP_WIZARD_STEPS[0]);
  const defaultPathForTranslation = 'components.setup_wizard.version_1.5';
  const { industries, useCases } = SETUP_WIZARD_INDUSTRIES_USE_CASES;
  const [selectedIndustry, setSelectedIndustry] = useState<string | undefined>(undefined);
  const [selectedUseCase, setSelectedUseCase] = useState<string | undefined>(undefined);
  const getTitleFromFilter = (key: string) =>
    t(`components.onboarding.questionnaire.questions.categories.options.${key.replace('-', '_')}`);

  const shouldShowErrorBanner =
    selectedIndustry === t(`${defaultPathForTranslation}.step_1.text_1`) ||
    selectedUseCase === t(`${defaultPathForTranslation}.step_1.text_2`);

  const goBack = () => setCurrentStep(SETUP_WIZARD_STEPS[0]);

  const getIndexOfStepForTexts = (step: string) => SETUP_WIZARD_STEPS.indexOf(step) + 1;

  const getStepPercentage = (currentStepIndex: number, totalSteps: number) =>
    ((currentStepIndex + 1) / totalSteps) * 100;
  return (
    <div className="p-18">
      <WizardLayout
        contentWidth="full"
        shouldShowCloseButton={false}
        hasHeader={false}
        hasFooter={false}
        dataTestid="setup-wizard-1.5"
      >
        {shouldShowErrorBanner && (
          <Banner intent="destructive" className="mb-6">
            {t(`${defaultPathForTranslation}.step_${getIndexOfStepForTexts(currentStep)}.error`)}
          </Banner>
        )}
        <div>
          <div className="text-xl font-medium text-emphasis">
            <h1>
              {t(`${defaultPathForTranslation}.step_${getIndexOfStepForTexts(currentStep)}.title`, {
                portal:
                  selectedUseCase && selectedUseCase !== 'other'
                    ? getTitleFromFilter(selectedUseCase)
                    : ''
              })}
            </h1>
          </div>
          <div className="my-4">
            <Progress
              className="max-w-[520px]"
              intent="brand"
              value={getStepPercentage(
                SETUP_WIZARD_STEPS.indexOf(currentStep),
                SETUP_WIZARD_STEPS.length
              )}
            />
          </div>
        </div>
        {currentStep === SETUP_WIZARD_STEPS[0] && (
          <div className="mt-20 w-full">
            <StepSelectIndustry
              setCurrentStep={setCurrentStep}
              selectedIndustry={selectedIndustry}
              setSelectedIndustry={setSelectedIndustry}
              selectedUseCase={selectedUseCase}
              setSelectedUseCase={setSelectedUseCase}
              defaultPathForTranslation={defaultPathForTranslation}
              getTitleFromFilter={getTitleFromFilter}
              industries={industries}
              useCases={useCases}
              isLoading={isLoading}
            />
          </div>
        )}
        {currentStep === SETUP_WIZARD_STEPS[1] && selectedIndustry && selectedUseCase && (
          <StepSelectCard
            selectedIndustry={selectedIndustry}
            selectedUseCase={selectedUseCase}
            sampleApps={sampleApps ?? []}
            defaultPathForTranslation={defaultPathForTranslation}
            onBack={goBack}
            getTitleFromFilter={getTitleFromFilter}
          />
        )}
      </WizardLayout>
    </div>
  );
}
