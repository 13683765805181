import { useContext, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { HiMiniSquaresPlus as CustomDataIcon } from 'react-icons/hi2';
import { Badge, Banner, Button, Card, ThemeProviderContext, useToast } from '@knack/asterisk-react';

import { type SampleApp } from '@/types/apps';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useAccountQuery } from '@/hooks/api/queries/useAccountQuery';
import { useSession } from '@/hooks/useSession';
import { usePostSignUpContext } from '@/contexts/PostSignUpContext';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getErrorMessage } from '@/utils/errors';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import { AiModal } from '@/pages/apps/create-app/ai-form/AiModal';
import {
  AppCreateType,
  BASE_IMAGE_URL,
  BUILDER_NEXT_IMPORT_PATH
} from '@/pages/apps/create-app/constants';
import {
  getCardImage,
  getTemplateAppsFilteredAndSorted,
  getUrlForAICard
} from './setup-wizard-helper';

interface StepSelectCardProps {
  selectedIndustry: string;
  selectedUseCase: string;
  sampleApps: SampleApp[];
  defaultPathForTranslation: string;
  onBack: () => void;
  getTitleFromFilter: (key: string) => string;
}

export function StepSelectCard({
  selectedIndustry,
  selectedUseCase,
  sampleApps,
  defaultPathForTranslation,
  onBack,
  getTitleFromFilter
}: StepSelectCardProps) {
  const [t] = useTranslation();
  const session = useSession();
  const [selectedTemplate, setSelectedTemplate] = useState<SampleApp | undefined>(undefined);
  const { data: account } = useAccountQuery();
  const { createAppFromSample, createApp, deleteApp } = useAppMutation();
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const { presentToast } = useToast();
  const { setIsSetupWizardEnabled } = usePostSignUpContext();
  const [appCreationError, setAppCreationError] = useState('');
  const [isAppLoading, setIsAppLoading] = useState(false);
  const { isDarkMode } = useContext(ThemeProviderContext);

  const handleSampleCreateApp = () => {
    const sample = sampleApps?.find((s) => s.id === selectedTemplate?.id);
    if (!sample) {
      return;
    }
    const sampleData = {
      appId: sample.id,
      templateSlug: sample.slug,
      appDescription: sample.description
    };
    if (account?.applications && account?.applications.length > 0) {
      // eslint-disable-next-line no-underscore-dangle
      const appId = account.applications[0]._id;
      deleteApp.mutate(appId);
    }
    createAppFromSample.mutate(sampleData, {
      onSuccess: async (response) => {
        if (shouldSendAnalytics(session.user.email)) {
          await window.analytics.track('Setup Wizard: New App From Sample Created', {
            app: sample.slug,
            groupId: session.account.id,
            industry: `${t(
              `components.onboarding.questionnaire.questions.categories.options.${selectedIndustry?.replace('-', '_')}`
            )}`,
            use_case: `${t(`components.onboarding.questionnaire.questions.categories.options.${selectedUseCase.replace('-', '_')}`)}`
          });
        }
        window.location.href = `${import.meta.env.PUBLIC_BUILDER_URL}/${session.account.slug}/${response.app.slug}/schema/list`;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        presentToast({ title: message, intent: 'destructive' });
      }
    });
  };

  const handleAppCreation = async (caseType: string) => {
    setIsSetupWizardEnabled(false);

    if (caseType === 'ai') {
      setShouldShowAiModal(true);
      return;
    }

    if (caseType === 'import') {
      setIsAppLoading(true);

      const appData = {
        appDescription: '',
        appOrigin: AppCreateType.Import,
        shouldCreateWithTable: true,
        shouldCreateDefaultUserRoles: true
      };

      createApp.mutate(appData, {
        onSuccess: (response) => {
          window.location.href = `${import.meta.env.PUBLIC_BUILDER_NEXT_URL}/${session.account.slug}/${response.application.slug}/${BUILDER_NEXT_IMPORT_PATH}`;
        },
        onError: (error) => {
          const message = getErrorMessage(
            error,
            t('components.create_app.create_app_error_message')
          );
          setAppCreationError(message);
          setIsAppLoading(false);
        }
      });
    }
  };

  const setConditionalClasses = (templateId: string) => {
    if (selectedTemplate) {
      if (selectedTemplate.id === templateId) {
        return 'border-brand-default border-2';
      }
      return 'opacity-50';
    }
    return '';
  };

  if (isAppLoading) {
    return <FullPageSpinner />;
  }

  return (
    <div className="flex flex-col items-center gap-6 px-4 py-6">
      {appCreationError && !isAppLoading && (
        <Banner
          intent="destructive"
          title={t('components.create_app.create_app_error_title')}
          className="my-4"
          closeMode="text"
        >
          <Banner.Message className="text-xs">{appCreationError}</Banner.Message>
        </Banner>
      )}
      <div className="mx-auto grid max-w-screen-lg grid-cols-1 gap-6 sm:grid-cols-2 lg:grid-cols-3">
        <Card
          key="customize"
          className={`flex flex-col items-start border-transparent p-4 transition-all sm:p-2 sm:pb-4 ${setConditionalClasses('customize')}`}
          onClick={async () => {
            if (shouldSendAnalytics(session.user.email)) {
              await window.analytics.track('Setup Wizard: Customize My App/AI clicked', {
                groupId: session.account.id
              });
            }
            void handleAppCreation('ai');
          }}
        >
          <img
            src={getUrlForAICard(selectedUseCase, isDarkMode)}
            alt={t(`${defaultPathForTranslation}.step_2.customize`, { portal: selectedUseCase })}
            className="h-48 w-full rounded-t-lg object-cover"
          />
          <h3 className="mt-4 font-semibold text-emphasis">
            {t(`${defaultPathForTranslation}.step_2.customize`, {
              portal:
                selectedUseCase && selectedUseCase !== 'other'
                  ? getTitleFromFilter(selectedUseCase)
                  : ''
            })}
          </h3>
          <p className="text-xs text-subtle">
            {t(`${defaultPathForTranslation}.step_2.customize_description`)}
          </p>
        </Card>
        <Card
          key="import"
          className={`flex flex-col items-start border-transparent p-4 transition-all sm:p-2 sm:pb-4 ${setConditionalClasses('import')}`}
          onClick={async () => {
            if (shouldSendAnalytics(session.user.email)) {
              void window.analytics.track('Setup Wizard: Start with a Table (Import) clicked', {
                groupId: session.account.id
              });
            }
            void handleAppCreation('import');
          }}
        >
          <img
            src={`//cdn.knack.com/assets/setup-wizard/images/v1.0.0/${isDarkMode ? 'dark' : 'light'}-start-table.svg`}
            alt={t(`${defaultPathForTranslation}.step_2.start_with_a_table`)}
            className="h-48 w-full rounded-t-lg object-cover"
          />
          <h3 className="mt-4 font-semibold text-emphasis">
            {t(`${defaultPathForTranslation}.step_2.start_with_a_table`)}
          </h3>
          <p className="text-xs text-subtle">
            {t(`${defaultPathForTranslation}.step_2.start_with_a_table_description`)}
          </p>
        </Card>
        {getTemplateAppsFilteredAndSorted(selectedIndustry, selectedUseCase, sampleApps)?.map(
          (template: SampleApp) => (
            <Card
              key={template.id}
              className={`flex flex-col items-start gap-2 rounded-lg border-2 border-transparent p-3 pb-4 shadow-md transition-all sm:p-2 sm:pb-4 ${setConditionalClasses(template.id)}`}
              onClick={() => setSelectedTemplate(template)}
            >
              {template.link &&
                (() => {
                  const cardImage = getCardImage(template.link);
                  if (cardImage) {
                    return cardImage.format === 'webp' ? (
                      <picture className="h-48 w-full rounded-t-lg object-cover">
                        <source
                          srcSet={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.webp`}
                          type="image/webp"
                        />
                        <img
                          src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.jpg`}
                          alt={template.title}
                          className="h-48 w-full rounded-t-lg object-cover"
                        />
                      </picture>
                    ) : (
                      <img
                        src={`${BASE_IMAGE_URL}template-apps-cards-images/${cardImage.urlSlug}.${cardImage.format}`}
                        alt={template.title}
                        className="h-48 w-full rounded-t-lg object-cover"
                      />
                    );
                  }
                  return (
                    <img
                      src={`${BASE_IMAGE_URL}template-apps-cards-images/warehouse-manager.jpg`}
                      alt={t(`${defaultPathForTranslation}.step_2.customize`, {
                        portal: selectedUseCase
                      })}
                      className="h-48 w-full rounded-t-lg object-cover"
                    />
                  );
                })()}
              <Badge intent="neutral" className="mt-2 text-xs">
                {t(`${defaultPathForTranslation}.step_2.template_badge`)}
              </Badge>
              <div className="flex w-full items-center justify-between">
                <h3 className="mt-2 font-semibold text-emphasis">{template.title}</h3>
              </div>
              <p className="text-xs text-subtle">{template.description}</p>
            </Card>
          )
        )}

        <Card key="more" className="flex flex-col items-center justify-center">
          <CustomDataIcon className="text-gradient-1 mb-6 fill-[url(#svg-gradient-2)]" size={60} />
          <Button intent="link" size="lg" asChild>
            <a href="https://www.knack.com/template-inventory" target="_blank" rel="noreferrer">
              {t(`${defaultPathForTranslation}.step_2.explore_more_templates`)}
            </a>
          </Button>
        </Card>
      </div>

      <div className="sticky mt-4 flex w-full justify-end">
        <Button
          disabled={createAppFromSample.isPending}
          onClick={() => {
            setSelectedTemplate(undefined);
            onBack();
          }}
          intent="minimal"
        >
          {t('actions.back')}
        </Button>
        <Button
          disabled={createAppFromSample.isPending || !selectedTemplate}
          onClick={handleSampleCreateApp}
          isLoading={createAppFromSample.isPending}
          className="ml-4"
        >
          {t('components.create_app.create_app')}
        </Button>
      </div>

      {shouldShowAiModal && (
        <AiModal
          open
          onClose={() => {
            setShouldShowAiModal(false);
          }}
        />
      )}
    </div>
  );
}
