import { useTranslation } from 'react-i18next';

import { NavItem } from './NavItem';

type NavItemListProps = {
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  filters: readonly string[];
  isSetupWizard: boolean;
};

export function NavItemList({
  selectedTab,
  setSelectedTab,
  filters,
  isSetupWizard
}: NavItemListProps) {
  const [t] = useTranslation();
  return filters.map((filter) => (
    <NavItem
      key={filter}
      isActive={selectedTab === filter}
      onClick={() => setSelectedTab(filter)}
      title={
        isSetupWizard
          ? t(
              `components.onboarding.questionnaire.questions.categories.options.${filter.replace('-', '_')}`
            )
          : t(`components.onboarding.questionnaire.questions.industry.options.${filter}`)
      }
      data-testid={`sample-apps-${filter}-nav-item`}
    />
  ));
}
