import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useSession } from '@/hooks/useSession';
import { shouldSendAnalytics } from '@/utils/analytics';
import { getAnonymousFlagValue, trackVariation } from '@/utils/flagsmith';
import { FullPageSpinner } from '@/components/ui/FullPageSpinner';
import ProductBackgroundWebp from '@/assets/img/product-background.png?w=1920&h=1080&format=webp&imagetools';
import ProductBackground from '@/assets/img/product-background.png?w=1920&h=1080&imagetools';
import { CreateAppFromSampleModal } from './CreateAppFromSampleModal';
import { SetupWizardSteps } from './template-apps/setup-wizard-v1.5/SetupWizardSteps';

export function SetupWizard() {
  const { t } = useTranslation();
  const [isOpen, setIsOpen] = useState(true);
  const session = useSession();
  const [variationId, setVariationId] = useState<string | null>();
  const trackEventSent = useRef(false);
  const [isAppLoading, setIsAppLoading] = useState(false);
  useEffect(() => {
    async function fetchVariation() {
      const flagValue = await getAnonymousFlagValue('setup_wizard_v1');
      setVariationId(flagValue);

      if (!trackEventSent.current) {
        void trackVariation({
          experimentId: 'setup_wizard_v1',
          experimentName: 'Setup Wizard V1',
          variationId: variationId ?? 'two_cta',
          variationMapping: {
            two_cta: 'Two CTA',
            three_cta: 'Three CTA'
          },
          accountId: session.account.id,
          userEmail: session.user.email,
          shouldSendAnalytics
        });
        trackEventSent.current = true;
      }
    }

    void fetchVariation();
  }, [variationId, session.account.id, session.user.email]);

  if (isAppLoading) {
    return <FullPageSpinner />;
  }

  return (
    <main className="flex min-h-screen">
      {variationId === 'two_cta' && (
        <div className="w-full flex-col">
          <SetupWizardSteps />
        </div>
      )}
      {variationId === 'three_cta' && (
        <>
          <picture>
            <source srcSet={ProductBackgroundWebp} type="image/webp" />
            <source srcSet={ProductBackground} type="image/png" />
            <img
              src={ProductBackground}
              alt={t('components.sign_up.background_alt')}
              className="absolute size-full object-cover"
            />
          </picture>
          <div className="absolute z-10 size-full bg-black/50 backdrop-blur" />
          <div className="z-20 my-auto max-w-[596px] rounded-xl bg-default p-12 pb-6">
            <CreateAppFromSampleModal
              open={isOpen}
              setIsOpen={setIsOpen}
              setIsAppLoading={setIsAppLoading}
              variationId="three_cta"
            />
          </div>
        </>
      )}
    </main>
  );
}
