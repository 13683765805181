import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Input, useToast } from '@knack/asterisk-react';

import { type ProductPlan } from '@/types/account';
import {
  useValidateCouponMutation,
  type Coupon
} from '@/hooks/api/mutations/useValidateCouponMutation';
import { getPriceWithCouponDiscount } from '@/utils/billing';
import { STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES } from '@/utils/constants';
import { FormControl } from '@/components/ui/FormControl';

interface ValidateCouponInterface {
  promoCodeValidated: string;
  couponValidated: Coupon | null;
}
interface InputValidateStripePromoCodeProps {
  selectedPlan: ProductPlan;
  onValidation: ({ promoCodeValidated, couponValidated }: ValidateCouponInterface) => void;
}

export function InputValidateStripePromoCode({
  selectedPlan,
  onValidation
}: InputValidateStripePromoCodeProps) {
  const validateCouponMutation = useValidateCouponMutation();
  const { presentToast } = useToast();
  const [t] = useTranslation();

  const [promoCodeInput, setPromoCodeInput] = useState<string>('');
  const [validatedCoupon, setValidatedCoupon] = useState<Coupon | null>(null);
  const [validationError, setValidationError] = useState<string | null>('');
  const [hasDiscountCampaignBeenEvaluated, setHasDiscountCampaignBeenEvaluated] = useState(false);
  const getPlanPrice = (withCoupon = false) => {
    let price =
      // if the plan is yearly, multiply the price by 10 (as we are giving two months for free)
      // otherwise, just use the price. This is because the price is always stored as monthly
      selectedPlan?.frequency !== undefined && selectedPlan.frequency === 'yearly'
        ? selectedPlan.price * 10
        : selectedPlan?.price;

    price = getPriceWithCouponDiscount(
      price,
      withCoupon ? validatedCoupon : null,
      selectedPlan?.frequency !== undefined && selectedPlan?.frequency === 'yearly'
    );
    return price;
  };

  const promoCodesStarterAndProCampaigns =
    import.meta.env.PUBLIC_IS_PRODUCTION === 'true'
      ? STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.prod
      : STARTER_PRO_DISCOUNT_CAMPAIGN_PROMO_CODES.test;

  const validateCoupon = async () => {
    if (!promoCodeInput.trim()) {
      return;
    }
    setValidationError('');

    validateCouponMutation.mutate(
      { promoCode: promoCodeInput, planId: selectedPlan.id },
      {
        onSuccess: (data) => {
          const { success, coupon, errorKey } = data;
          // Coupon exists and is valid
          if (success && coupon) {
            onValidation({ promoCodeValidated: promoCodeInput, couponValidated: coupon });
            setValidatedCoupon(coupon);
            presentToast({ title: t('components.billing.payment.coupon.applied') });
            // Coupon exists but is invalid
          } else {
            onValidation({ promoCodeValidated: promoCodeInput, couponValidated: null });
            setValidatedCoupon(null);
            setValidationError(
              errorKey
                ? t(`components.billing.payment.coupon.${errorKey}`)
                : t('components.billing.payment.coupon.invalid')
            );
          }
        },
        onError: () => {
          // Coupon does not exist
          onValidation({ promoCodeValidated: promoCodeInput, couponValidated: null });
          setValidatedCoupon(null);
          setValidationError(t('components.billing.payment.coupon.invalid'));
        }
      }
    );
  };

  useEffect(() => {
    if (!selectedPlan?.id.endsWith('_y')) {
      if (selectedPlan.id.includes('starter')) {
        setPromoCodeInput(promoCodesStarterAndProCampaigns.starter);
      }
      if (selectedPlan.id.includes('pro')) {
        setPromoCodeInput(promoCodesStarterAndProCampaigns.pro);
      }
      setHasDiscountCampaignBeenEvaluated(true);
    }
  }, [selectedPlan.id]);

  useEffect(() => {
    if (promoCodeInput) {
      void validateCoupon();
    }
  }, [hasDiscountCampaignBeenEvaluated]);

  return (
    <FormControl className="mb-6">
      <FormControl.Label htmlFor="coupon">
        {t('components.billing.payment.coupon.title')}
      </FormControl.Label>
      <div className="flex w-full justify-between">
        <Input
          type="text"
          id="coupon"
          name="coupon"
          className="mr-1 w-3/4"
          placeholder={t('components.billing.payment.coupon.placeholder')}
          intent={validationError ? 'destructive' : undefined}
          onChange={(event) => {
            const { value } = event.target;
            setPromoCodeInput(value);

            if (validationError) {
              setValidationError('');
            }
          }}
          value={promoCodeInput}
        />
        <Button
          className="ml-1 w-1/4"
          type="button"
          onClick={validateCoupon}
          disabled={!promoCodeInput.trim()}
          isLoading={validateCouponMutation.isPending}
        >
          {t('actions.validate')}
        </Button>
      </div>
      {validationError && (
        <FormControl.Message type="error" className="text-sm">
          {validationError}
        </FormControl.Message>
      )}
      {validatedCoupon && (
        <p className="mt-1 text-justify">
          {t('components.billing.payment.coupon.next_invoice')}
          <span className="text-sm font-light line-through">${getPlanPrice()} </span>
          <span className="text-md font-bold">${getPlanPrice(true)}</span>
        </p>
      )}
    </FormControl>
  );
}
