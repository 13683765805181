import React, { forwardRef, useLayoutEffect, useRef, useState } from 'react';
import { DndContext, useDraggable } from '@dnd-kit/core';
import { restrictToWindowEdges } from '@dnd-kit/modifiers';
import { CSS } from '@dnd-kit/utilities';
import type { Coordinates } from '@dnd-kit/utilities';

import { ChecklistsExpandedSection } from '@/components/checklists/ChecklistsExpandedSection';
import { ChecklistsMainContent } from '@/components/checklists/ChecklistsMainContent';
import { ChecklistsPopoverActions } from './ChecklistsPopoverActions';

type ChecklistsPopoverProps = {
  coordinates: Coordinates;
  onClose: () => void;
};

type ChecklistsPopoverContentProps = {
  triggerRef: React.MutableRefObject<HTMLElement | null>;
  onClose: () => void;
};

function ChecklistsPopover(
  { coordinates, onClose }: ChecklistsPopoverProps,
  ref: React.ForwardedRef<HTMLElement>
) {
  const [currentSection, setCurrentSection] = useState<string | null>(null);
  const { setNodeRef, transform } = useDraggable({
    id: 'checklist-draggable'
  });

  const style = {
    transform: CSS.Transform.toString(transform),
    left: `${coordinates.x}px`,
    bottom: `${coordinates.y}px`
  };

  const setPopoverRef = (element: HTMLElement | null) => {
    setNodeRef(element);

    if (typeof ref === 'function') {
      ref(element);
    } else if (ref) {
      // eslint-disable-next-line no-param-reassign
      ref.current = element;
    }
  };

  return (
    // Disabling these rules as we just need the onClick to stop propagation to the parent element
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      ref={setPopoverRef}
      style={style}
      className="absolute z-50 animate-slideDownAndFade"
      onClick={(e) => e.stopPropagation()}
    >
      <div className="relative flex w-[400px] select-none flex-col gap-2 rounded-xl border border-subtle bg-card px-6 py-4 shadow-[0_10px_60px_0_rgba(0,0,0,0.16)] outline-0">
        <ChecklistsPopoverActions onClose={onClose} />
        {currentSection ? (
          <ChecklistsExpandedSection id={currentSection} onGoBack={() => setCurrentSection(null)} />
        ) : (
          <ChecklistsMainContent onSectionClick={(section) => setCurrentSection(section)} />
        )}
      </div>
    </div>
  );
}

const ChecklistsPopoverWithRef = forwardRef(ChecklistsPopover);

export function ChecklistsPopoverContent({ triggerRef, onClose }: ChecklistsPopoverContentProps) {
  const [coordinates, setCoordinates] = useState<Coordinates>({
    x: 0,
    y: 0 // Inverted (from bottom to top as the element starts in the bottom)
  });
  const popoverRef = useRef<HTMLElement>(null);

  useLayoutEffect(() => {
    const targetElement = triggerRef.current;
    const popoverElement = popoverRef.current;

    if (!targetElement || !popoverElement) return;

    let x = targetElement.offsetLeft;

    // Calculate y based on: the bottom of the screen being y = 0
    let y = window.innerHeight - (targetElement.offsetTop + targetElement.offsetHeight);

    const popoverWidth = popoverElement.offsetWidth;
    const popoverHeight = popoverElement.offsetHeight;
    const windowWidth = window.innerWidth;

    if (x + popoverWidth > windowWidth) {
      const adjustedX = windowWidth - popoverWidth;
      x = adjustedX >= 0 ? adjustedX : 0;
    }

    if (y < popoverHeight) {
      y = popoverHeight;
    }

    setCoordinates({ x, y: y - popoverHeight - 8 }); // 8 being the padding we want to leave to the element
  }, [triggerRef, popoverRef]);

  return (
    <DndContext
      modifiers={[restrictToWindowEdges]}
      onDragEnd={({ delta }) => {
        setCoordinates(({ x, y }) => ({
          x: x + delta.x,
          y: y - delta.y
        }));
      }}
    >
      <ChecklistsPopoverWithRef ref={popoverRef} coordinates={coordinates} onClose={onClose} />
    </DndContext>
  );
}
