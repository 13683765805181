import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsStars as AiStarsIcon } from 'react-icons/bs';
import {
  HiArrowUpTray as FileUploadIcon,
  HiSquaresPlus as SampleIcon,
  HiPencilSquare as ScratchIcon
} from 'react-icons/hi2';
import { Button, DropdownMenu, Spinner, Tooltip } from '@knack/asterisk-react';

import { useAccountRestrictions } from '@/hooks/account/useAccountRestrictions';
import { useAppMutation } from '@/hooks/api/mutations/useAppMutation';
import { useIsSharedBuilderOnly, useSession } from '@/hooks/useSession';
import { getErrorMessage } from '@/utils/errors';
import { cn } from '@/utils/tailwind';
import { CreateAppFromSampleModal } from '@/pages/apps/create-app/CreateAppFromSampleModal';
import { AiModal } from './ai-form/AiModal';
import { AppCreateType, BUILDER_NEXT_IMPORT_PATH } from './constants';

type MenuItemProps = {
  icon: React.ReactNode;
  label: string;
  onClick?: () => void;
  disabled: boolean;
  isHighlighted?: boolean;
  dataTestId?: string;
  dataFeatureId?: string;
};

function MenuItem({
  icon,
  label,
  dataTestId,
  dataFeatureId,
  onClick,
  disabled,
  isHighlighted = false
}: MenuItemProps) {
  return (
    <DropdownMenu.Item
      onSelect={onClick}
      disabled={disabled}
      data-feature={dataFeatureId}
      data-feature-x-offset={20}
      data-feature-y-offset={20}
    >
      <div
        className={cn('flex items-center space-x-2', isHighlighted && 'text-brand')}
        data-testid={dataTestId}
      >
        {icon}
        <span>{label}</span>
      </div>
    </DropdownMenu.Item>
  );
}

export function CreateAppButton({
  disabled = false,
  isHipaa = false,
  setAppCreationError
}: {
  disabled?: boolean;
  isHipaa?: boolean;
  setAppCreationError: (error: string) => void;
}) {
  const [t] = useTranslation();
  const session = useSession();
  const isSharedBuilderOnly = useIsSharedBuilderOnly();
  const { createApp } = useAppMutation();
  const shouldShowAi = import.meta.env.PUBLIC_ENABLE_AI_APP_CREATION !== 'false';
  const [selectedType, setSelectedType] = useState<AppCreateType | null>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [shouldShowAiModal, setShouldShowAiModal] = useState(false);
  const [shouldShowModal, setShouldShowModal] = useState(false);
  const accountRestrictions = useAccountRestrictions();
  const shouldShowWarningTooltip =
    accountRestrictions?.suspended ||
    accountRestrictions?.reachedAppLimit ||
    accountRestrictions?.frozen ||
    accountRestrictions?.trialExpired ||
    isSharedBuilderOnly;

  function handleCreateApp(type: AppCreateType) {
    setSelectedType(type);
    setIsDropdownOpen(true);
    const data = {
      appDescription: '',
      appOrigin: type,
      shouldCreateWithTable: true,
      shouldCreateDefaultUserRoles: type === AppCreateType.Import || type === AppCreateType.Scratch
    };

    createApp.mutate(data, {
      onSuccess: (response) => {
        setIsDropdownOpen(false);
        setSelectedType(null);
        const initialTable = response.application?.objects?.[0] ?? null;
        let builderDomain = import.meta.env.PUBLIC_BUILDER_URL;
        let builderPath = '';

        if (type === AppCreateType.Import) {
          builderPath = BUILDER_NEXT_IMPORT_PATH;
          builderDomain = import.meta.env.PUBLIC_BUILDER_NEXT_URL;
        } else if (initialTable) {
          builderPath = `schema/list`;
        }
        const redirect = `${builderDomain}/${session.account.slug}/${response.application.slug}/${builderPath}`;
        window.location.href = redirect;
      },
      onError: (error) => {
        const message = getErrorMessage(error, t('components.create_app.create_app_error_message'));
        setAppCreationError(message);
        setIsDropdownOpen(false);
        setSelectedType(null);
      }
    });
  }

  function renderTooltipText() {
    switch (true) {
      case accountRestrictions?.suspended:
        return t('components.apps.tooltip_suspended');
      case accountRestrictions?.frozen:
        return t('components.apps.tooltip_frozen');
      case accountRestrictions?.trialExpired:
        return t('components.apps.tooltip_trial_expired');
      case accountRestrictions?.reachedAppLimit:
        return t('components.apps.tooltip_limit_reached');
      case isSharedBuilderOnly:
        return t('components.apps.tooltip_shared_builder');
      default:
        return t('components.apps.tooltip_default');
    }
  }

  return (
    <>
      <CreateAppFromSampleModal
        open={shouldShowModal && !shouldShowAiModal}
        setIsOpen={setShouldShowModal}
      />
      <DropdownMenu
        modal={false}
        open={isDropdownOpen}
        onOpenChange={(open) => {
          if (isDropdownOpen && selectedType) return;
          setIsDropdownOpen(open);
        }}
      >
        {/* We don't want to show any tooltip at all if there are not restrictions applied. */}
        <Tooltip open={shouldShowWarningTooltip ? undefined : false}>
          <Tooltip.Trigger asChild>
            <div>
              <DropdownMenu.Trigger asChild aria-label="create new app options">
                <Button
                  className="whitespace-nowrap py-0"
                  data-testid="apps-create-new-app-button"
                  data-feature="create_app_button"
                  disabled={disabled}
                >
                  {t('components.create_app.create_new_app')}
                </Button>
              </DropdownMenu.Trigger>
            </div>
          </Tooltip.Trigger>
          <Tooltip.Content>{renderTooltipText()}</Tooltip.Content>
        </Tooltip>
        <DropdownMenu.Content align="end">
          {shouldShowAi && (
            <MenuItem
              icon={<AiStarsIcon className="rotate-90" />}
              label={t('components.create_app.ai.generate_with_ai')}
              disabled={createApp.isPending}
              onClick={() => setShouldShowAiModal(true)}
              dataTestId="apps-create-new-app-with-ai-option"
              dataFeatureId="ai_button"
            />
          )}
          {!isHipaa && (
            <MenuItem
              disabled={createApp.isPending}
              icon={<SampleIcon />}
              label={t('components.create_app.start_from_sample_app')}
              onClick={() => setShouldShowModal(true)}
              dataTestId="apps-create-new-app-from-sample-option"
            />
          )}
          <MenuItem
            icon={
              createApp.isPending && selectedType === AppCreateType.Import ? (
                <Spinner size="sm" />
              ) : (
                <FileUploadIcon />
              )
            }
            label={t('components.create_app.import_data')}
            dataTestId="apps-create-new-app-import-csv-option"
            onClick={() => handleCreateApp(AppCreateType.Import)}
            disabled={createApp.isPending}
          />
          <MenuItem
            icon={
              createApp.isPending && selectedType === AppCreateType.Scratch ? (
                <Spinner size="sm" />
              ) : (
                <ScratchIcon />
              )
            }
            label={t('components.create_app.start_from_scratch')}
            dataTestId="apps-create-new-app-from-scratch-option"
            onClick={() => handleCreateApp(AppCreateType.Scratch)}
            disabled={createApp.isPending}
          />
        </DropdownMenu.Content>
      </DropdownMenu>
      {shouldShowAiModal && (
        <AiModal
          open
          onClose={() => {
            setShouldShowAiModal(false);
            setShouldShowModal(false);
          }}
        />
      )}
    </>
  );
}
