import { DashboardIllustration } from '@/pages/apps/create-app/illustrations/DashboardIllustration';
import { DataManagementIllustration } from '@/pages/apps/create-app/illustrations/DataManagementIllustration';
import { DirectoryIllustration } from '@/pages/apps/create-app/illustrations/DirectoryIllustration';
import { ListIllustration } from '@/pages/apps/create-app/illustrations/ListIllustration';
import { PortalIllustration } from '@/pages/apps/create-app/illustrations/PortalIllustration';
import { ReportsIllustration } from '@/pages/apps/create-app/illustrations/ReportsIllustration';
import { type SampleAppIllustration } from '@/pages/apps/create-app/illustrations/types';

export const ILLUSTRATIONS: Record<string, SampleAppIllustration> = {
  dashboard: {
    component: <DashboardIllustration />,
    className: 'bg-illustration-blue'
  },
  data_management: {
    component: <DataManagementIllustration />,
    className: 'bg-illustration-pink'
  },
  directory: {
    component: <DirectoryIllustration />,
    className: 'bg-illustration-green'
  },
  list: {
    component: <ListIllustration />,
    className: 'bg-illustration-orange'
  },
  portal: {
    component: <PortalIllustration />,
    className: 'bg-illustration-purple'
  },
  reports: {
    component: <ReportsIllustration />,
    className: 'bg-illustration-yellow'
  }
} as const;
